.fade {
  opacity: 1;
  transition: opacity 0.15s linear;
}

/* Start Retool iFrame classes */
.RetoolApp {
  text-align: center;
  width: 100vw;
  height: 80vh;
  background-image: url("./ipaws-logo_blue-text.png");
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 0%;
  padding: 0%;
}

.navbar-brand {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size:calc(12px + .6vw);
  margin: 0;
} 

/* .navbar-brand-group { 
  display: flex;
  align-items: center;
  flex-direction: row;
}

.brand-text {
  justify-content: left;
}

.brand-emerg {
  font-weight: 800;

  .row {
    margin-left: 0rem;
    justify-content: center;
    padding: 0;

    .button {
      margin-left: 0vw;
    }

  }

}

.login-margin {
  .nav-link {
    margin-left: 4rem;
    justify-content: center;
  }
} */
.emerg-nav {
  background-color: #a9cff0d0 !important;
}

.emerg-container{
  display: flex;
  flex-wrap: wrap;
}

.brand-emerg {
  justify-content: space-around;
  width: 40vw;
  .btn {
    font-weight: 800;
  }
}


.brand-emerg-span {
  font-size:calc(9px + .9vw);
  font-weight: 300;
  /* padding:0; */
  /* justify-self: center; */
} 

.brand-emerg-span {
  width: 30vw;
}

@media (max-width: 767px) {
.login-margin {
  display: none;
}
}

/*
.margin-left-0 {
  margin-left: calc(15vw);
}

@media (max-width: 767px) {
  .navbar-brand {
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 0;
  }

  .brand-text {
    justify-content: center;
  }

  .margin-left-0 {
    margin-left: 0;
  }

  .login-margin {
    display: none;
  }

  .brand-emerg {
    font-weight: 800;
  
    .row {
      margin-left: 0rem;
      justify-content: center;
      padding: 0;
  
      .button {
        width: 80vw;
      }
  
    }
  
  }

}

@media (min-width: 1050px) {
  .navbar-brand {
    font-size: 18.3px;
  }
} */

.retool-box {
  height: 100%;
}

.retoolContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: scroll;
}

.container-fluid {
  padding: 0;
}

/* End Retool iFrame classes */

/* This overrides the default Bootstrap button colors. */
.btn-danger {
  color: #000000;
  background-color: #e25651d4;
  border-color: #d43f3a;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #183b59;
}

/* This has custom button colors */
.btn-signup {
  color: #fff;
  background-color: #299b42e8;
  border-color: #183b59;
}

.btn-login {
  color: #fff;
  background-color: #299b42e8;
  border-color: #183b59;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #337ab7;
}

.nav-link {
  color: #337ab7;
}

.logoImg {
  width: 6rem;
  height: 100%;
  margin-top: 0%;
}


/* This class prevents the Alert from always being hidden. */
.loginAlert {
  transition: all .5s;
  opacity: 1;
  margin-left: 20%;
  margin-right: 20%;
}

.login-card {
  transition: all .5s;
  background-color: rgba(64, 98, 131, 0.067);
  max-width: 8000px;
  margin-top: 0%;
  /* border-radius: 2%; */
}

.login-form {
  transition: all .5s;
  padding: 2%;
  margin-left: 1%;
  margin-right: 1%;
}

.login-title {
  transition: all .5s;
  font-size: 22px; 
  font-weight: 700;
  color: #337ab7 !important;
  line-height: 1.3em;
  margin: auto;
  text-align: center;
}

.login-card {
  transition: all .5s;
  background-color: rgba(64, 98, 131, 0.067);
  max-width: 8000px;
  margin-top: 0%;
  /* border-radius: 2%; */
}

.MFAChangeDiv {
  max-width: 8000px;
  width: 100%;
}

.logged-in-user {
  font-weight: 700;
  line-height: 1.3em;
  margin: auto;
  text-align: center;
}

@media (max-width: 900px) {
  .logged-in-user {
    font-size: calc(1vw + 0.5em);
  }

  .footer-links {
    font-size: calc(.5vw + 0.1em);
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .login-card {
    transition: all .5s;
    background-color: rgba(64, 98, 131, 0.067);
    max-width: 8000px;
    margin-top: 0%;
    /* border-radius: 2%; */
    margin-left: 10%;
    margin-right: 10%;
  }

  .login-form {
    transition: all .5s;
    padding: 5%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .MFAChangeDiv {
    margin-left: 10%;
    margin-right: 10%;
  }

  .app-title {
    font-size: 27px;
    font-weight: 700;
    line-height: 1.3em;
    margin: auto;
    text-align: center;
  }

}

@media (min-height: 800px) {
  .login-title {
    transition: all .5s;
    font-size: 38px;
    font-weight: 700;
    color: #337ab7 !important;
    line-height: 1em;
    margin: auto;
    text-align: center;
  }
}


.centeredCard {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 5%;
}

.appCard {
  width: "25rem";
  align-self: "center";
}

.appContainer {
  border-color: #ffffff00;
}

.roundedCard {
  border-radius: 25px;
  background: rgba(0, 0, 0, 0.03);
  border: none;
}

.modernFont {
  font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial,
    sans-serif;
}

.accordion-body {
  background-color: rgba(0, 0, 0, 0.03);
}

.mvButtonSize {
  /* width: 25rem; */
  margin: 1rem;
  height: 100%;
}

.mfa-tooltip {
  z-index: 1000;
}
.app-dropdown-red a {
  color: #e80000 !important;
}

.dropdown-menu {
  transform: translateX(-22%);
}

.footerBar {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  margin-bottom: 0px;
}

.outlet-div {
  margin-bottom: 80px;
  /* background-color: rgba(64, 98, 131, 0.067); */
  /* max-width: 1200px; */
  margin-top: 1%;
  border-color: #ffffff00;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.dashboardHeader {
  font-size: 30px;
}

/* This class prevents the Alert from always being hidden. */
.COGIDPasswordAlert {
  opacity: 1;
  margin-left: 0%;
  margin-right: 0%;
}

.opacity-fix {
  opacity: 1;
}

.app-title {
  font-size: auto;
  font-weight: 700;
  /* color: #337ab7 !important; */
  line-height: 1.3em;
  margin: auto;
  text-align: center;
}

.app-img {
  /* make it so that Col that this image is inside get's narrower than the image, that the image shink with the Col */
  max-width: 100%;
  height: auto;


}

/* User Dashboard */

.user-dashboard-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.user-dashboard-card {
  width: 1000px;
  max-width: 100%;
  margin: 1rem;
  padding: 1rem;
  border-radius: 25px;
  background: rgba(0, 0, 0, 0.03);
  border: none;
}

.accordion {
  /* background-color: #f1f1f1; */
  color: #337ab7;
  cursor: pointer;
  padding: 18px;
  width: 900px;
  max-width: 90%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.7s;
}

.language-download-container {
  display: flex;
  align-items: center;
}

.language-dropdown {
  padding: 10px 15px;
  margin-left: 8rem;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  color: #333;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}